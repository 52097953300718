<template>
    <el-dialog
        class="rel-role"
        title="绑定角色"
        :visible.sync="isShow"
        width="625px"
        @open="handlerOpen"
        @closed="handlerReset"
        :close-on-click-modal="false"
    >
        <el-transfer
            filterable
            :filter-method="filterMethod"
            filter-placeholder="请输入角色名称"
            v-model="hasRoles"
            :data="allRoles"
            :titles="['未绑定', '绑定']"
            :props="props"
            v-loading="loading"
        >
        </el-transfer>

        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="handlerClose">关闭</el-button>
            <el-button size="mini" @click="handlerReset" :disabled="loading"
                >重置</el-button
            >
            <el-button
                type="primary"
                size="mini"
                @click="handlerSave"
                :disabled="loading"
                >保存</el-button
            >
        </span>
    </el-dialog>
</template>
<script>
import { getUserRolesApi, bindUserRolesApi } from "@/api/system/user.js";
import { getAllRoleApi } from "@/api/system/role.js";

export default {
    name: "RelRole",
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        data: {
            type: Object
        }
    },
    computed: {
        isShow: {
            get: function() {
                return this.visible;
            },
            set: function(v) {
                this.$emit("update:visible", v);
            }
        }
    },
    data() {
        return {
            loading: false,
            props: {
                key: "id",
                label: "role_name"
            },
            hasRoles: [],
            allRoles: [],
            bindRoles: []
        };
    },
    methods: {
        async getAllRoles() {
            return new Promise((resolve, reject) => {
                getAllRoleApi()
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "allRoles", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        async getUserRoles() {
            return new Promise((resolve, reject) => {
                getUserRolesApi(this.data)
                    .then(res => {
                        if (res.data.code == 0) {
                            this.$set(this, "bindRoles", res.data.data);
                            resolve(res.data.data);
                        } else {
                            this.$notify.error({
                                title: "失败",
                                message: res.data.msg
                            });
                            reject();
                        }
                    })
                    .catch(error => {
                        this.$notify.error({
                            title: "异常",
                            message: error
                        });
                        reject();
                    });
            });
        },
        handlerReset() {
            this.$set(
                this,
                "hasRoles",
                this.bindRoles.map(item => item.id)
            );
        },
        async handlerOpen() {
            this.loading = true;
            await this.getAllRoles();
            await this.getUserRoles();
            this.handlerReset();
            this.loading = false;
        },
        handlerClose() {
            this.isShow = false;
            this.$set(this, "hasRoles", []);
            this.$set(this, "allRoles", []);
            this.$set(this, "bindRoles", []);
        },
        filterMethod(query, item) {
            return item.role_name.indexOf(query) > -1;
        },
        handlerSave() {
            this.loading = true;
            bindUserRolesApi({
                id: this.data.id,
                roles: this.hasRoles
            })
                .then(res => {
                    if (res.data.code == 0) {
                        this.$notify({
                            title: "成功",
                            message: "绑定成功",
                            type: "success"
                        });
                        this.handlerClose();
                    } else {
                        this.$notify.error({
                            title: "失败",
                            message: res.data.msg
                        });
                    }
                })
                .catch(error => {
                    this.$notify.error({
                        title: "异常",
                        message: error
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.rel-role .roles-selector {
    padding: 0 20px;
}
</style>
