import request from "@/router/axios";

export const getUserPageApi = params => {
    return request({
        url: "/api/system/user/page",
        method: "get",
        params
    });
};

export const addUserApi = data => {
    return request({
        url: "/api/system/user/insert",
        method: "post",
        data
    });
};

export const updateUserApi = data => {
    return request({
        url: "/api/system/user/update",
        method: "put",
        data
    });
};

export const deleteUserApi = data => {
    return request({
        url: "/api/system/user/delete",
        method: "delete",
        data
    });
};

export const getUserRolesApi = params => {
    return request({
        url: "/api/system/user/roles",
        method: "get",
        params
    });
};

export const bindUserRolesApi = data => {
    return request({
        url: "/api/system/user/bindRoles",
        method: "post",
        data
    });
};
